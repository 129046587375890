/* Main container for all cards */
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around; /* Center cards horizontally */
}

/* Individual card type container */
.card-type {
  flex: 1 1 100%; /* Full width by default */
  max-width: 100%; /* Ensure no overflow */
  padding: 10px; /* Add padding around the card types */
}

/* Grid layout for cards */
.card-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* 3 columns on medium+ screens */
  gap: 20px; /* Gap between cards */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .card-columns {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* 2 columns on smaller screens */
  }
}

/* For screens smaller than 576px (e.g., mobile phones) */
@media (max-width: 575px) {
  .card-columns {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Adjusted column width for smaller screens */
    gap: 10px; /* Smaller gap for mobile screens */
  }
}

/* Base styles for all cards */
.convo-card {
  position: relative;
  border: 3px solid #fff; /* Default border */
  border-radius: 12px;
  box-sizing: border-box; /* Include border in width/height calculation */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%; /* Take full width of its container */
  height: 40vh; /* Each card will take about half the height of the screen */
}

/* Specific borders for different card types */
.convo-card.reflection {
  border: 3px solid #ECB7FF; /* Purple border for reflection cards */
}

.convo-card.fun {
  border-color: #B1EB96; /* Green border for fun cards */
}

.convo-card.aboutyou {
  border-color: #4ED6D6; /* Cyan border for about you cards */
}

.convo-card.together {
  border-color: #FEE252; /* Yellow border for together cards */
}

.convo-card.memories {
  border-color: #A5A5A5; /* Gray border for memories cards */
}

.convo-card.storytime {
  border-color: #FFB359; /* Orange border for storytime cards */
}

.convo-card.dealbreakers {
  border-color: #FF9292; /* Red border for dealbreakers cards */
}

/* Text inside each card */
.ccard-text {
  font-size: 18px; /* Adjust font size as needed */
  color: #333;
  margin: 0; /* Remove default margin */
}
