hr {
    margin-left: 0;
    border-top: 2px solid #070707;
    width: 30%;
}

.card{
    border: none;
    margin-bottom: 8rem;
}

.card:last-child{
    margin-bottom: 4rem;
}

.coming-soon{
    color: #222222;
    opacity: 0.65;
}

.card-img {
    transition: transform 0.2s ease-in-out; /* zoom in */
    cursor: pointer;
    border-radius: 10px;
}

.card-img:hover{
    transform: translate3D(0,-1px,0) scale(1.02);
}

.no-hover.card-img{
    transform: none;
    cursor: default;
}

/* .card-title {
    font-size: 24px;
    font-weight: 600;
} */

.appType{
    /* color: #7d7d7d; */
    color: #89868f;
    font-weight: 400;
    /* font-style: italic; */
}

.see-more{
    color: inherit !important;
    text-decoration: none;
    font-weight: 400;
    transition: all .25s;
}

.see-more:hover{
    color: inherit;
    text-decoration: none;
}

/* Buttons */
.btn{
    border-radius: 15px;
    border: 1px solid #acacac;
    font-size: inherit;
    transition: .2s ease-in-out; /* zoom in */
}

.btn:hover{
    /* border-color: #9087A8; */
    box-shadow: 0 0 3px #C9C4D4;
    /* background-color: #e7e3f0; */
    /* background-color: #C9C4D4; */
}
