body {
    color: #070707;
    font-family: "Poppins";
    font-size: 17px;
    font-weight: 300;
    line-height: 1.9em;

    margin: 0;
}

h1 {
    /* font-size: 36px; */
    /* font-size: 38px; */
    font-weight: 600;
}

h2 {
    font-size: 30px;
    font-weight: 400;
}

h3 {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0em;
}

h4 {
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 0em;
    margin-top: 4px;
}

h5 {
    font-size: inherit;
    font-weight: 500;
    
    margin-bottom: 3px;
}

@media (max-width:575px) {
    h1 {
        font-size: 30px;
    }

    h2{
        font-size: 24px;
    }

    h3{
        font-size: 20px;
    }

    h4{
        font-size: 17px;
    }

    body{
        font-size: 15px;
    }
}

li {
    margin-bottom: .2rem;
}

strong {
    font-weight: 300;
    font-style: italic;
}

/* Link background highlight */
a:not(.nav-link):not(.navbar-brand):not(.btn):not(.card-img-link) {
    color: #9087A8;
    text-decoration: none;
    background: linear-gradient(90deg, #DEDBE6 0%, #DEDBE6 100%);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 14%;
    transition: background-size .2s;
}

/* only do full highlight on hover on non-mobile devices */
@media (min-width:481px) {
    a:not(.nav-link):not(.navbar-brand):not(.btn):hover {
        background-size: 100% 14%;
    }
}

.full-height{
    height: 85vh;
    width: 100vw;
}

.blockquote {
    background: #f4f2f9;
    border-left: 10px solid #9087A8;
    margin: 1.5em 10px;
    font-style: italic;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
}

.blockquote:before {
    color: #9087A8;
    content: open-quote;
    font-size: 3.2em;
    line-height: 0.1em;
    margin-right: 0.1em;
    vertical-align: -0.4em;
}

.big-margin-top {
    margin-top: 81px;
}

.mouse-pointer {
    cursor: pointer;
}

.img-zoom {
    cursor: zoom-in;
}

.carousel .carousel-indicators li {
    background-color: #ffffff;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-bottom: 0;
    margin-right: 2px;
    margin-left: 2px;
}

.carousel .carousel-indicators {
    margin-bottom: 0;
}

.carousel .carousel-indicators .active {
    background-color: rgb(89, 89, 89);
}

.carousel .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel .carousel-control-prev, .carousel .carousel-control-next {
    width: auto;
    background: none !important;
}