h5 {
  margin-top: 15px;
}

h5:first-child {
  margin-top: 0px;
}

p.speech-bubble {
  position: relative;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  border: 4px solid #9087A8;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 50px;
  padding: 2rem;
}

.project-overview-details div{
  line-height: auto;
}

.project-overview-details ul{
  list-style: none;
  padding: 0px;
  line-height: 1.7rem;
}

.project-overview-details ul li{
  margin: 0.5em 0px;
}

li:last-child {
  margin-bottom: 0px;
}

section {
  margin-bottom: 100px;
}

.credit{
  text-align: right;
  font-size: 14px;
  font-style: italic;
}
@media (max-width:575px) {
 .credit{
      font-size: 12px;
  }
}

/* Sidebar */
.sidecar {
  border-left: solid #CDC9D8 4px;
  padding-left: 15px;
  z-index: 9;
  bottom: 3rem;
}

.sidecar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 1.25em;
  text-transform: uppercase;
}

.sidecar ul li {
  margin: .5em 0rem;
  cursor: pointer;
  font-weight: 500;
}

.sidecar ul li:first-child {
  margin-top: 0px;
}

.sidecar ul span {
  /* background-color: #DEDBE6; */
  background: linear-gradient(90deg, #DEDBE6 0%, #DEDBE6 100%);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 14%; /*Thickness of line*/
  transition: background-size .2s;
}

.sidecar ul span:hover {
  /* background-color: #DEDBE6; */
  background-size: 100% 14%; /*Thickness of line*/
}

/* Nested links */
.sidecar ul>ul li {
  margin-left: 10px;
  font-weight: 300;
  text-transform: capitalize;
}

.sidecar.sticky-top {
  top: 5em;
}

@media (max-width: 767.98px) {
  section {
    margin-bottom: 40px;
  }
}
