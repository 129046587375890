.purple {
    color: #9087A8;
}

.name{
    /* letter-spacing: 0.15em; */
    font-weight: 600;
}

.wavingHand{
    cursor: default;
}

.headline{
    line-height: 2.25rem;
    /* font-style: italic; */
    /* font-weight: 300; */
}

.homepage.big-margin-top {
    margin-top: 40px;
}

.infocards.big-margin-top{
    margin-top: 65px;
    /* margin-top: 110px; */
}

@media (max-width:575px) {
    .homepage.big-margin-top{
        margin-top: 20px;
    }

    .infocards.big-margin-top{
        margin-top: 55px;
    }
}


.wavingHand:hover {
    animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s;        /* Change to speed up or slow down */
    animation-iteration-count: infinite;  /* Never stop waving */
    transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
    display: inline-block;
  }
  
  @keyframes wave-animation {
      0% { transform: rotate( 0.0deg) }
     10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
     20% { transform: rotate(-8.0deg) }
     30% { transform: rotate(14.0deg) }
     40% { transform: rotate(-4.0deg) }
     50% { transform: rotate(10.0deg) }
     60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
    100% { transform: rotate( 0.0deg) }
  }