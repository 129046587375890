h2.about{
    /* font-weight: 500; */
    line-height: 2.7rem;
}

h5.about{
    line-height: 1.9em;
}

/* On sm sceens and smaller */
@media (max-width: 575px) {
    h2.about {
        line-height: 2.2rem;
    }
}