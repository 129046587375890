.nav-link{
    margin-left: 15px;
    margin-right: 0px;

    font-size: 15px;
    font-weight: 400;
    color: #070707;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    text-align: center;
}

/* On md and smaller, adjust margin btwn links */
@media (max-width: 767px) {
    .nav-link{
        margin-left: 7px;
    }
}

/* on small screens/mobile */
@media (max-width: 575px) {
    .nav-link{
        margin-left: 0px;

        text-transform: capitalize;
        text-align: left;
    }

    #overrides .navbar-toggler{
        border: none;
    }
}

.active{
    font-weight: 500;
}

#overrides .navbar-brand{
    color: #070707;
    font-size: 15.5px;
    font-weight: 500;
    letter-spacing: 0.06em;
    text-align: center;
}